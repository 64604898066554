<template>
  <div class="mobile-global-form">
    <v-row no-gutters>
      <v-col cols="12">
        <calendesk-information-message>
          {{ $trans("mobile_account_editor_global_logo_image_message") }}
        </calendesk-information-message>
      </v-col>
    </v-row>
    <v-row no-gutters class="mobile-global-form__logo">
      <v-col cols="12">
        <calendesk-image-select
          v-model="form.tenants_mobile_image_logo"
          outline
          :select-type="selectType.MOBILE_HOME_IMAGE"
          :label="$trans('web_global_logo')"
          :select-image-button-text="$trans('select')"
          :display-remove-image-button="false"
          contain
          @input="changeImageClientConfigurationsBlur"
        />
      </v-col>
    </v-row>
    <v-row no-gutters class="mobile-global-form__primary-color">
      <v-col cols="12">
        <color-picker
          v-model="form.tenants_mobile_color_primary"
          :label="$trans('web_global_primary_color')"
          @input="setPrimaryColor"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import selectType from "@/components/assets/selectType";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";

export default {
  name: "MobileGlobalForm",
  components: {
    CalendeskInformationMessage,
    CalendeskImageSelect: () =>
      import("@/lib/calendesk-js-library/components/CalendeskImageSelect"),
    ColorPicker: () =>
      import("@/lib/calendesk-js-library/components/CColorPicker"),
  },
  mixins: [sharedActions],
  data() {
    return {
      menu: false,
      form: {
        tenants_mobile_color_primary: "",
        tenants_mobile_image_logo: {},
      },
      selectType,
    };
  },
  computed: {
    ...mapGetters({
      getConfiguration: "mobileEditor/getConfiguration",
      getColors: "mobileEditor/getColors",
    }),
  },
  watch: {
    getConfiguration() {
      this.updateFields();
    },
  },
  mounted() {
    this.updateFields();
  },
  methods: {
    ...mapMutations({
      setColors: "mobileEditor/SET_COLORS",
    }),
    ...mapActions({
      changeConfiguration: "mobileEditor/changeConfiguration",
    }),
    updateFields() {
      if (!this.getConfiguration) return;
      this.form.tenants_mobile_color_primary =
        this.getConfiguration.tenants_mobile_color_primary;
      this.form.tenants_mobile_image_logo =
        this.getConfiguration.tenants_mobile_image_logo;
    },
    setPrimaryColor() {
      this.setColors({
        tenants_mobile_color_primary: this.form.tenants_mobile_color_primary,
      });
      this.changeConfigurationsBlur();
    },
    changeImageClientConfigurationsBlur() {
      this.changeConfiguration({
        settings: {
          tenants_mobile_image_logo: this.form.tenants_mobile_image_logo,
        },
      });
    },
    changeConfigurationsBlur() {
      this.changeConfiguration({ settings: { ...this.getColors } });
    },
  },
};
</script>

<style lang="scss" scoped>
.mobile-global-form .row {
  margin-bottom: 2rem;
}

.mobile-global-form__edit_colors .v-btn {
  width: 100%;
  border-color: rgba(0, 0, 0, 0.38);
}
</style>
